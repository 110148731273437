.container {
  display: flex;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}

.content {
  min-width: 100%;
  padding: 25px 15px;
  background-color: var(--header-color);
  overflow-y: auto;
}

.title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.6875rem;
  color: var(--header-text-color);

  &.mb60 {
    margin-bottom: 60px;
  }
}

.list {
  &Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid var(--header-border-color);

    svg {
      path {
        stroke: var(--header-text-color);
      }
    }

    &Text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--header-text-color);
    }
  }
}

.goBack {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  svg {
    path {
      fill: var(--header-text-color);
    }
  }

  &Text {
    display: flex;
    margin-left: 5px;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--header-text-color);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;

  svg {
    path {
      stroke: var(--header-text-color);
    }
  }
}

.subcategoryBtn {
  margin-top: 15px;
  color: var(--white);
  background-color: var(--primary);

  &:hover {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
  }
}
